nav {
  background-color: var(--neutrals-bg-white);
  width: 272px;
  height: 100vh;
  padding: 16px;
  overflow-y: auto;
}
.logo {
  padding-right: 30px;
  margin-bottom: 50px;
}
.nav-link {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 5px;
}
.nav-link:hover,
.nav-link.active {
  background-color: var(--main-blue);
}
.nav-link:hover span,
.nav-link.active span {
  color: white;
}
.nav-link:hover svg path,
.nav-link.active svg path {
  fill: white;
}
.link-svg {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.logout-btn {
  margin-top: auto;
  background-color: var(--alerts-danger);
  border: 1px solid var(--alerts-danger);
  padding-left: 10px;
  cursor: pointer;
  color: white;
}
.logout-btn:hover {
  border-color: var(--main-blue);
}
