.login-container {
  width: 100vw;
  height: 100vh;
  background-color: var(--blue-shades-1);
}
.footer {
  width: 100%;
  height: 96px;
  background-color: var(--neutrals-white);
}
.footer {
  font-size: 14px;
}
.footer span {
  margin-left: 5px;
}
.form-container {
  width: 100%;
  height: calc(100% - 96px);
}
.form {
  width: 80%;
  max-width: 480px;
  padding: 52px 32px;
  border-width: 0px;
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.31);
}
.login-submit {
  margin-top: 60px;
}
