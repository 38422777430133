.circle {
  display: inline-block;
  background-color: var(--main-blue);
  height: 15px;
  width: 15px;
  border-radius: 25px;
}
.apploader {
  width: 100vw;
  min-height: 100vh;
}

#loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ball-1 {
  -webkit-animation-name: bounce;
  -webkit-animation-delay: 1s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-2 {
  -webkit-animation-name: bounce;
  -webkit-animation-delay: 1.1s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}
#ball-3 {
  -webkit-animation-name: bounce;
  -webkit-animation-delay: 1.2s;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(23px);
  }
  60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(13px);
  }
  60% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(0);
  }
}
